import { useCallback } from "react"
import { useUser } from "../providers/UserProvider"
import useApplicationFeeDetails from "./useApplicationFeeDetails"
import {
  calculateStageStatuses,
  StageStatusResults,
  Status as importedStatus,
} from "../utility/calculateStatuses"
import { Schema } from "../data/question-schema"
import useQuestionStatuses from "./useQuestionStatuses"
import useServiceStatus from "./useServiceStatus"

// Make the status easy to reference for when this is being imported
export const Status = importedStatus

const useStageStatuses = (questions: Schema): StageStatusResults => {
  const { activity } = useStageStatuses.useUser()
  const applicationFeeDetails = useStageStatuses.useApplicationFeeDetails()
  const questionStatuses = useStageStatuses.useQuestionStatuses(questions)
  const serviceStatus = useStageStatuses.useServiceStatus()

  const memoizedCalculateStageStatuses = useCallback(() => {
    return calculateStageStatuses(
      questionStatuses,
      activity,
      applicationFeeDetails,
      serviceStatus
    )
  }, [questionStatuses, applicationFeeDetails, activity, serviceStatus])

  return memoizedCalculateStageStatuses()
}

useStageStatuses.useUser = useUser
useStageStatuses.useApplicationFeeDetails = useApplicationFeeDetails
useStageStatuses.useQuestionStatuses = useQuestionStatuses
useStageStatuses.useServiceStatus = useServiceStatus

export default useStageStatuses
