import { useUser } from "../providers/UserProvider"
import usePaymentConfig from "./usePaymentConfig"
import usePaymentTokens from "./usePaymentTokens"
import { ApplicationFeeDetails } from "@ap-frontend/types"
export {
  HydratedApplicationFeeDetails,
  ApplicationFeeDetails,
} from "@ap-frontend/types"

// The WPM pay option for the application fee.
export const APPLICATION_FEE_PAY_OPTION = "UGAAP"

/**
 * A hook that provides a convenient way to get detail of the applicant's application fee status.
 */
const useApplicationFeeDetails = (): ApplicationFeeDetails | undefined => {
  const config = usePaymentConfig()

  const { profile } = useUser()

  const paymentTokens = usePaymentTokens()

  if (profile === undefined) return undefined

  // Check if the applicant needs to pay.

  if (profile.waiveApplicationFee || !profile.toPayApplicationFee) return {}

  if (config === undefined) return undefined

  // Retrieve the `APPLICATION_FEE_PAY_OPTION` chargeable item.

  const chargeableItem = config.chargeableItems.find(
    item => item.payOption === APPLICATION_FEE_PAY_OPTION
  )

  if (chargeableItem === undefined) {
    throw new Error(
      `No chargeable item for ${APPLICATION_FEE_PAY_OPTION} is configured`
    )
  }

  if (paymentTokens === undefined) return undefined

  if (!(chargeableItem.amountToPay in paymentTokens)) {
    throw new Error(
      `No payment token is available for ${chargeableItem.amountToPay}`
    )
  }

  // Attempt to retrieve and return a token that has been used to pay the application fee.

  const paymentToken = paymentTokens[chargeableItem.amountToPay].find(
    paymentToken => paymentToken.status?.success
  )

  if (paymentToken) return { chargeableItem, paymentToken }

  // Attempt to retrieve and return a token that can be used to pay the application fee.

  return {
    paymentToken: paymentTokens[chargeableItem.amountToPay].find(
      paymentToken => !paymentToken.status
    ),
    chargeableItem,
  }
}

export default useApplicationFeeDetails
