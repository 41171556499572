import { useCallback } from "react"
import useQuestionAnswers from "./useQuestionAnswers"
import {
  calculateQuestionStatuses,
  Status as importedStatus,
  StatusResults,
} from "../utility/calculateStatuses"
import { useUser } from "../providers/UserProvider"
import { Schema } from "../data/question-schema"

// Make the status easy to reference for when this is being imported
export const Status = importedStatus

const useQuestionStatuses = (questions: Schema): StatusResults | undefined => {
  const { profile } = useQuestionStatuses.useUser()
  const answers = useQuestionStatuses.useQuestionAnswers()

  const memoizedCalculateQuestionStatuses = useCallback(() => {
    return calculateQuestionStatuses(questions, answers, profile)
  }, [questions, profile, answers])

  return memoizedCalculateQuestionStatuses()
}

useQuestionStatuses.useUser = useUser
useQuestionStatuses.useQuestionAnswers = useQuestionAnswers

export default useQuestionStatuses
