import { useEffect, useState } from "react"

/**
 * These are the details of a chargeable item as definined in the config.
 */
export interface ChargeableItem {
  /**
   * A description of the item
   */
  description: string
  /**
   * A WPM code defining the item
   */
  payOption: string
  /**
   * The cost of the item
   */
  amountToPay: string
}

/**
 * All payment configuration values.
 */
export interface PaymentConfig {
  /**
   * The WPM payment request endpoint.
   */
  wpmUrl: string
  /**
   * These are environment specific `PaymentRequest` values that apply to all payment requests.
   */
  basePaymentRequest: {
    clientId: number
    pathwayId: number
    departmentId: number
    emailFrom: string
    callbackUrl: string
  }
  /**
   * These are environment specific `Payment` values that apply to all payment requests.
   */
  basePayment: {
    amountToPayVat: string
    vatDesc: string
    vatCode: string
    vatRate: string
  }
  /**
   * This is a list of chargeable items: their descriptions and price.
   */
  chargeableItems: ChargeableItem[]
}

/**
 * A hook that provides a convenient way to get the payment configuration for using the
 * `PaymentButton`.
 */
const usePaymentConfig = (): PaymentConfig | undefined => {
  const [config, setConfig] = useState<PaymentConfig | undefined>(undefined)

  useEffect(() => {
    if (!config) {
      fetch(`/payment-config.json?t=${new Date().getTime()}`)
        .then(response => response.json())
        .then(paymentConfig => {
          setConfig(paymentConfig)
        })
    }
  }, [config, setConfig])

  return config
}

export default usePaymentConfig
