import { useContextSelector } from "use-context-selector"
import {
  AnswersSchema,
  QuestionAnswerContext,
} from "../providers/QuestionAnswerProvider"

const useQuestionAnswers = (): AnswersSchema | undefined => {
  return useContextSelector(QuestionAnswerContext, ({ data }) => data)
}

export default useQuestionAnswers
