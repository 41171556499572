import React, { ReactElement } from "react"
import SEO from "../../components/SEO"
import Link from "../../components/Link"
import { PageProps } from "gatsby"
import { BasePageContext } from "../../interfaces/PageContext"
import T from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Chip from "@mui/material/Chip"
import Skeleton from "../../components/Skeleton"
import Alert from "@mui/material/Alert"
import Container from "@mui/material/Container"
import { styled } from "@mui/material/styles"
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { Status } from "@ap-frontend/types"
import { useUser } from "../../providers/UserProvider"
import { Timestamp } from "firebase/firestore"
import {
  formatDate,
  InvalidDateError,
  formatDateTime,
} from "@ap-frontend/utility"
import { visuallyHidden } from "@mui/utils"
import { typeStyles } from "@ucam/helix-tokens"
import useStageStatuses from "../../hooks/useStageStatuses"
import useQuestionStatuses from "../../hooks/useQuestionStatuses"
import WarningIcon from "@mui/icons-material/WarningAmberOutlined"

const ScreenReaderOnlyText = styled("span")(() => ({ ...visuallyHidden }))

interface StageDetails {
  id: string
  heading: ReactElement | string
  subheading?: ReactElement | string
  status?: Status
  children?: ReactElement | string
}

const statusDisplays: {
  [key: string]: {
    color: "primary" | "secondary" | "default" | "error"
    label: string
  }
} = {
  [Status.InProgress]: {
    color: "secondary",
    label: "In progress",
  },
  [Status.Complete]: {
    color: "primary",
    label: "Complete",
  },
  [Status.NotStarted]: {
    color: "default",
    label: "Not started",
  },
  [Status.CannotStart]: {
    color: "default",
    label: "Cannot start yet",
  },
  [Status.NotAvailable]: {
    color: "default",
    label: "Not available",
  },
  // Only appears on submission stage
  // And shouldn't be possible, but cloud function would have logged an error if so
  [Status.Error]: {
    color: "error",
    label: "Error",
  },
}

const StageStatusChip = ({ status }: { status?: Status }): ReactElement => {
  if (status === Status.NotRequired)
    throw new Error("app/not-required-status-rendered")

  if (!status) return <Skeleton width="6rem" height="2rem" />

  const statusDisplay = statusDisplays[status]
  return <Chip color={statusDisplay.color} label={statusDisplay.label} />
}

interface StageProps extends Omit<StageDetails, "id"> {
  index: number
}

const StagePaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  border: `1px solid ${theme.palette.grey[200]}`,
}))

const NumberIndicator = styled("span")(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

const PaperHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}))

const PaperBody = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  borderTop: `1px solid ${theme.palette.grey[200]}`,
}))

const Stage = ({
  heading,
  status,
  children,
  subheading,
  index,
  ...rest
}: StageProps): ReactElement => {
  return (
    <StagePaper elevation={1} {...rest}>
      <PaperHeader>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: ["column", "row"],
            alignItems: ["baseline", "center"],
          }}
        >
          <T
            variant="h4"
            component="h2"
            sx={{
              mb: 0,
              mt: 0,
              flexGrow: 1,
              ...(status === Status.NotRequired ? { color: "grey.600" } : {}),
            }}
            data-test-id="stage-heading"
          >
            <NumberIndicator>{index + 1}.</NumberIndicator>
            <>{heading}</>
          </T>
          <Box sx={{ pt: [1, 0], pl: [0, 1] }} data-test-id="stage-status">
            <StageStatusChip status={status} />
          </Box>
        </Box>
        {subheading}
      </PaperHeader>
      {children ? (
        <PaperBody data-test-id="stage-body">{children}</PaperBody>
      ) : (
        <></>
      )}
    </StagePaper>
  )
}

const iconStyles = {
  color: "primary.main",
  mr: 3,
  mt: "1px",
  width: "1.5rem",
  height: "1.5rem",
}

/**
 * The application home page shows the applicants progress
 */
export const StageStatusDisplay = ({
  questions,
}: BasePageContext): ReactElement => {
  let deadlineDate

  const stageStatuses = StageStatusDisplay.useStageStatuses(questions)
  const { profile, activity } = StageStatusDisplay.useUser()
  const questionStatuses = StageStatusDisplay.useQuestionStatuses(questions)

  if (stageStatuses.payment === Status.Error) {
    // Overwrite this to enable link to the page to work.
    stageStatuses.payment = Status.NotStarted
  }

  const isLoading =
    stageStatuses.applicationDetails === undefined ||
    stageStatuses.questions === undefined ||
    stageStatuses.payment === undefined ||
    stageStatuses.submit === undefined

  try {
    deadlineDate = formatDate(
      profile?.deadline as `${number}-${number}-${number}`
    )
  } catch (error) {
    if (error instanceof InvalidDateError) {
      deadlineDate = ""
    } else {
      throw error
    }
  }

  const stages: StageDetails[] = [
    {
      id: "applicationDetails",
      heading: <Link to="details">Check your UCAS application details</Link>,
      status: stageStatuses.applicationDetails,
    },
    {
      id: "questions",
      heading: "Questions",
      status: stageStatuses.questions,
      children: (
        // TODO: break this into it's own component that uses `useQuestionStatuses` hook
        <Box>
          {questions.sections
            .map(section => ({
              section: section,
              status: questionStatuses?.sections.get(section.id),
            }))
            .filter(({ section, status }) => {
              if (status === Status.NotRequired) return false

              // Don't briefly show conditional sections if not loaded yet
              if (isLoading && section.conditions !== undefined) return false

              return true
            })
            .map(({ section, status }) => (
              <Box
                key={section.id}
                sx={{ display: "flex", mt: 2, mb: 3 }}
                data-test-section={section.id}
              >
                {isLoading ? (
                  <Skeleton variant="circular" sx={iconStyles} />
                ) : status === Status.Complete ? (
                  <CheckCircleIcon sx={iconStyles} />
                ) : (
                  <CircleOutlinedIcon sx={iconStyles} />
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    flexDirection: ["column", "row"],
                  }}
                >
                  <T
                    variant="h4"
                    component="h3"
                    sx={{
                      fontWeight: "fontWeightRegular",
                      flexGrow: 1,
                      mb: 1,
                      mt: 0,
                    }}
                  >
                    {section.title}
                  </T>
                  <Box data-test-id="link-wrapper">
                    {isLoading ? (
                      <Skeleton width="4.5rem" />
                    ) : (
                      <>
                        {stageStatuses.questions !== Status.CannotStart && (
                          <Link
                            to={
                              status === Status.NotStarted
                                ? section.path
                                : section.path + "/review"
                            }
                          >
                            {status === Status.Complete && "Review"}
                            {status === Status.InProgress && "Continue"}
                            {status === Status.NotStarted && "Start"}{" "}
                            <ScreenReaderOnlyText>
                              {section.title}
                            </ScreenReaderOnlyText>
                          </Link>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>
      ),
    },
    {
      id: "payment",
      heading:
        stageStatuses.payment &&
        [Status.NotStarted, Status.InProgress].includes(
          stageStatuses.payment
        ) ? (
          <Link to="payment">Pay the application fee</Link>
        ) : (
          "Pay the application fee"
        ),
      subheading:
        stageStatuses.payment === Status.NotAvailable ? (
          <T
            variant="body2"
            sx={{ color: "error.dark", display: "flex", mt: 1, mb: 0 }}
          >
            <WarningIcon sx={{ mr: 1, color: "error.main" }} />
            <span>Our payment system is not available</span>
          </T>
        ) : undefined,
      // Don't display payment stage while loading
      // As it may not be needed
      status: stageStatuses.payment || Status.NotRequired,
    },
    {
      id: "submit",
      heading:
        stageStatuses.submit &&
        [Status.InProgress, Status.NotStarted].includes(
          stageStatuses.submit
        ) ? (
          <Link to="submit">Submit your application</Link>
        ) : (
          "Submit your application"
        ),
      status: stageStatuses.submit,
    },
  ]

  return (
    <>
      {activity?.submissionTimestamp !== undefined && (
        <Alert severity="success" sx={{ mb: 3 }}>
          <T variant="body1" component="div">
            Your application was submitted on{" "}
            {formatDateTime(
              (activity.submissionTimestamp as Timestamp).toDate()
            )}{" "}
            (UK time)
          </T>
        </Alert>
      )}
      <Container
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          alignItems: "flex-start",
        }}
      >
        <T variant="h1" component="h1">
          My Cambridge Application
        </T>
        <T
          variant="body1"
          component="div"
          sx={{
            flexDirection: "row",
            mt: { md: 1, xs: 0 },
            ml: { md: "auto", xs: 0 },
          }}
        >
          <T
            data-test-id="deadline-date"
            sx={{ fontSize: typeStyles.paragraph.paragraph4.fontSize }}
          >
            {isLoading ? (
              <Skeleton sx={{ maxWidth: 550 }} />
            ) : deadlineDate ? (
              <>Submit by 6pm (UK time) on {deadlineDate}</>
            ) : (
              <Link href="https://www.undergraduate.study.cam.ac.uk/applying/dates-and-deadlines">
                Check your application deadline
              </Link>
            )}
          </T>
        </T>
      </Container>

      <Box sx={{ p: 0, listStyle: "none" }}>
        {stages
          .filter(stage => stage.status !== Status.NotRequired)
          .map((stage, i) => (
            <Stage
              key={stage.id}
              index={i}
              heading={stage.heading}
              subheading={stage.subheading}
              status={stage.status}
              data-test-stage={stage.id}
            >
              {stage.children}
            </Stage>
          ))}
      </Box>
    </>
  )
}

StageStatusDisplay.useUser = useUser
StageStatusDisplay.useStageStatuses = useStageStatuses
StageStatusDisplay.useQuestionStatuses = useQuestionStatuses

const ApplicationHomePage = ({
  pageContext,
}: PageProps<never, BasePageContext>): ReactElement => {
  return (
    <>
      <SEO title="My Cambridge Application" />
      <StageStatusDisplay questions={pageContext.questions} />
    </>
  )
}

export default ApplicationHomePage
