import { useUser } from "../providers/UserProvider"
import { useFirestoreDoc } from "./useFirestoreDoc"
import { PaymentToken } from "@ap-frontend/types"
export { PaymentStatus, PaymentToken } from "@ap-frontend/types"

/**
 * A hook that provides a convenient way to get an applicant's payment tokens.
 */
const usePaymentTokens = (): { [key: string]: PaymentToken[] } | undefined => {
  const { applicationNumber } = useUser()

  const { data } = useFirestoreDoc<{
    [key: string]: PaymentToken[]
  }>(`applicants/${applicationNumber}/userReadonly/paymentTokens`)

  return data
}

export default usePaymentTokens
